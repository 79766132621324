/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Global cross-site imports

// import "@popperjs/core/dist/umd/popper.min.js";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";

// import * as bootstrap from "bootstrap"
import ReactDOM from "react-dom/client"

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const onClientEntry = () => {
  // window.bootstrap = bootstrap

  // Aggiungi Google Analytics
  const loadAnalytics = () => {
    const script1 = document.createElement('script')
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-422B57XZQE"
    script1.async = true
    document.body.appendChild(script1)

    const script2 = document.createElement('script')
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-422B57XZQE');
    `
    document.body.appendChild(script2)
  }

  // Esegui loadAnalytics dopo che il DOM è completamente caricato
  if (document.readyState === 'complete') {
    loadAnalytics()
  } else {
    window.addEventListener('load', loadAnalytics)
  }
}
