exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---packages-gatsby-wp-archetipi-digitali-src-pages-404-js": () => import("./../../../../packages/gatsby-wp-archetipi-digitali/src/pages/404.js" /* webpackChunkName: "component---packages-gatsby-wp-archetipi-digitali-src-pages-404-js" */),
  "component---packages-gatsby-wp-archetipi-digitali-src-templates-about-index-js": () => import("./../../../../packages/gatsby-wp-archetipi-digitali/src/templates/about/index.js" /* webpackChunkName: "component---packages-gatsby-wp-archetipi-digitali-src-templates-about-index-js" */),
  "component---packages-gatsby-wp-archetipi-digitali-src-templates-blank-index-js": () => import("./../../../../packages/gatsby-wp-archetipi-digitali/src/templates/blank/index.js" /* webpackChunkName: "component---packages-gatsby-wp-archetipi-digitali-src-templates-blank-index-js" */),
  "component---packages-gatsby-wp-archetipi-digitali-src-templates-front-page-index-js": () => import("./../../../../packages/gatsby-wp-archetipi-digitali/src/templates/front-page/index.js" /* webpackChunkName: "component---packages-gatsby-wp-archetipi-digitali-src-templates-front-page-index-js" */),
  "component---packages-gatsby-wp-archetipi-digitali-src-templates-news-index-js": () => import("./../../../../packages/gatsby-wp-archetipi-digitali/src/templates/news/index.js" /* webpackChunkName: "component---packages-gatsby-wp-archetipi-digitali-src-templates-news-index-js" */),
  "component---packages-gatsby-wp-archetipi-digitali-src-templates-page-index-js": () => import("./../../../../packages/gatsby-wp-archetipi-digitali/src/templates/page/index.js" /* webpackChunkName: "component---packages-gatsby-wp-archetipi-digitali-src-templates-page-index-js" */),
  "component---packages-gatsby-wp-archetipi-digitali-src-templates-post-index-js": () => import("./../../../../packages/gatsby-wp-archetipi-digitali/src/templates/post/index.js" /* webpackChunkName: "component---packages-gatsby-wp-archetipi-digitali-src-templates-post-index-js" */),
  "component---packages-gatsby-wp-archetipi-digitali-src-templates-project-archive-index-js": () => import("./../../../../packages/gatsby-wp-archetipi-digitali/src/templates/project-archive/index.js" /* webpackChunkName: "component---packages-gatsby-wp-archetipi-digitali-src-templates-project-archive-index-js" */),
  "component---packages-gatsby-wp-archetipi-digitali-src-templates-project-index-js": () => import("./../../../../packages/gatsby-wp-archetipi-digitali/src/templates/project/index.js" /* webpackChunkName: "component---packages-gatsby-wp-archetipi-digitali-src-templates-project-index-js" */),
  "component---packages-gatsby-wp-archetipi-digitali-src-templates-service-index-js": () => import("./../../../../packages/gatsby-wp-archetipi-digitali/src/templates/service/index.js" /* webpackChunkName: "component---packages-gatsby-wp-archetipi-digitali-src-templates-service-index-js" */)
}

